<template>
  <div>
    <div class="card-body row justify-content-end desktop-only" v-if="false">
      <div class="d-flex flex-row ml-sm-auto mr-4 mt-4">
        <button
          class="mr-2 btn btn-sm btn-theme radius-99"
          @click="openCreateTaskModal"
          :disabled="!providerUserIds.length"
        >
          <span
            ><i class="mr-2 fas fa-envelope-open-text"></i>Message My
            Provider</span
          >
        </button>
      </div>
    </div>
    <sidebar
      :toggle="openModal"
      @close="closeCreateTaskModal"
      class="create-task-modal"
    >
      <Create
        :insideModal="true"
        @saved="taskSaved"
        :noRedirect="true"
        :defaultTaskType="7"
        :defaultPriority="2"
        :assignee="providerUserIds"
        :key="modalKey"
        v-if="openModal"
      ></Create>
    </sidebar>
    <div class="card-body">
      <div class="DashboardSection">
        <div class="row m-0 col-12">
          <dashboard-appointments class="DashboardItem mt-0" :maxItems="100" />
          <div
            class="col-12 chartContainer dashboard-item-container shadow-card"
            id="myContacts"
          >
            <div
              class="row m-0 justify-content-between dashboard-item-header mb-4"
            >
              <div class="dashboard-item-title">My Contacts</div>
              <div class="see-all pointer-cursor u-display-none">
                See All <i class="fas fa-chevron-right"></i>
              </div>
            </div>
            <Contacts
              class="DashboardItem pt-0"
              v-if="userInfo && userInfo.id"
              :maxItems="5"
              :patientId="userInfo.id"
              :hideDetails="true"
            ></Contacts>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { default as Create } from "../tasks/Create";
import { default as Contacts } from "../patients/profile/contacts/Index";
import DashboardAppointments from "../../components/dashboardAppointments.vue";
export default {
  name: "Index",
  props: ["userId"],
  components: {
    Create,
    Contacts,
    DashboardAppointments,
  },
  computed: {
    ...mapState({
      patients: (s) => s.patients.data,
      user: (state) => state.auth.user,
      userInfo: (state) => state.auth.userInfo,
    }),
  },
  created() {
    this.getUserInfo().then((res) => {
      this.getProviders(res.id).then((providers) => {
        if (providers && providers.length) {
          this.providerUserIds = providers.map((item) => item.user_id);
        }
      });
    });
  },
  data() {
    return {
      openModal: false,
      modalKey: 1,
      providerUserIds: [],
    };
  },
  methods: {
    ...mapActions({
      getUserInfo: "auth/getUserInfo",
      getProviders: "patients/providers/getBasicProviders",
    }),
    openCreateTaskModal: function () {
      this.openModal = true;
    },
    closeCreateTaskModal: function () {
      this.openModal = false;
      window.localStorage.removeItem("task_form");
    },
    taskSaved: function () {
      this.openModal = false;
      window.localStorage.removeItem("task_form");
      this.modalKey++;
    },
    navigateToCalender: function () {
      this.$router.push({ path: "/calendar" });
    },
    navigateToProfile: function () {
      this.$router.push({ path: "/profile" });
    },
  },
};
</script>
